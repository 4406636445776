
<template>
  <BackofficeBase :loader_prop="loader_prop">
 <DistributionPointMenu :point_id_prod="point_id_prod" />
   <h1>עריכת תיבות</h1>
   <h2 class = "unis_title">{{distribution_point_name}}</h2>
            <router-link :to="{path : '/backoffice/add_box', query : {'id' : point_id_prod }}">
        הוספת תיבה
      </router-link>
   <div v-for="(box) in view_data" :key="box.id" class ="order_box">
       <ul>
           <li >
             <button @click="open_door(box.id)">Open</button>
           </li>
       </ul> 
       <!-- <ul>
           <li v-if = "box.door_status=='open'">
             <button @click="close_door(box.id)">Close</button>
           </li>
           <li v-if = "box.door_status=='close'">
             <button @click="open_door(box.id)">Open</button>
           </li>
       </ul>  -->
       <ul>
           <li><h2>{{box.number}} :מספר תיבה</h2></li>
           <li>{{box.connect_status}} :סטטוס חיבור</li>
           <li>{{box.door_status}} :סטטוס דלת</li>
           <li> גודל: {{box.size}}</li>
           <li>
             <router-link :to="{path : '/backoffice/edit_box_size', query : {'id' : box.id}}">
               עריכה
             </router-link>
           </li>
       </ul>
       <ul>
           <li style = "color: red">
               <h3>{{box.fault}}</h3>
           </li>
       </ul>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
const backendModel = new BackendModel()
export default {
  name: 'BackofficeDistributionPoints',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop : true,
      view_data : [],
      point_id_prod : 0
    }

  },
  async mounted() {
    this.id = this.$route.query.id
    this.point_id_prod = this.$route.query.id
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/admin_get_boxes", {"id": this.id})
    this.view_data = view_data.data.view_data
    this.distribution_point_name = view_data.data.distribution_point_name[0].name
    this.distribution_point_id = view_data.data.distribution_point_id[0]
    // console.log(this.view_data)
    for (let i in this.view_data){
      if (this.view_data[i].size == "s"){
        this.view_data[i].size = "קטן"
      }
      if (this.view_data[i].size == "m"){
        this.view_data[i].size = "בינוני"
      }
      if (this.view_data[i].size == "l"){
        this.view_data[i].size = "גדול"
      }
    }
    this.loader_prop = false
  },
  methods: {
    async close_door(id){
      let id_for_vue = this.view_data.map(function(x) {return x.id; }).indexOf(id);
      this.view_data[id_for_vue].door_status = 'close'
      await backendModel.backendRequest("/Api/service/backoffice/admin_close_box", {id})
    },
    async open_door(id){
      let id_for_vue = this.view_data.map(function(x) {return x.id; }).indexOf(id);
      this.view_data[id_for_vue].door_status = 'open'
      await backendModel.backendRequest("/Api/service/backoffice/admin_open_box", {id})
      
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

